var maps = [];
$(document).ready(function() {
    $( ".mobileNav" ).click(function() {
        val = $('.head ul').hasClass('active');
        if(val){
            $(this).find('i').removeClass('icon-close').addClass('icon-menu');
            $('.head ul').removeClass('active');
        }else{
            $(this).find('i').addClass('icon-close').removeClass('icon-menu');
            $('.head ul').addClass('active');
        }
  });
  var owl = $('.owl-carousel');
  owl.owlCarousel({
      loop: true,
      margin: 10,
      nav: true,
      dots: true,
      navText: ['<i class="icon icon-arrow-left"><i>','<i class="icon icon-arrow-right"><i>'],
      items: 1,
      autoplay:true,
      autoplayTimeout:5000
  });
  renderMaps();
  $('input').iCheck({
    checkboxClass: 'icheckbox_square-blue',
    radioClass: 'iradio_square-blue',
    increaseArea: '20%' // optional
  });

  $(function () {
      $("input,select,textarea").not("[type=submit]").jqBootstrapValidation();
  } );

});

function renderMaps(){
    if($( "body" ).has( ".map" )){
        var LeafIcon = L.Icon.extend({
            options: {
                iconSize:     [44, 60],
                iconAnchor:   [22, 60]
            }
        });
        var icons  = new LeafIcon({iconUrl: url+'img/map.svg'});
        $( ".map" ).each(function( index ) {
            obj  = $( this );
            zoom = 8;
            if($(this).attr('data-zoom') != '')  zoom = parseInt($(this).attr('data-zoom'));
            lat  = parseFloat($(this).attr('data-lat'));
            lng  = parseFloat($(this).attr('data-lng'));

            if(lat != '' && lng != ''){
                map = L.map($(this).attr('id'),{scrollWheelZoom: false}).setView([lat, lng], zoom);
                maps.push(map);
                L.tileLayer('https://api.tiles.mapbox.com/v4/{id}/{z}/{x}/{y}.png?access_token={accessToken}', {
                    maxZoom: 18,
                    id: 'mapbox.light',
                    accessToken: 'pk.eyJ1Ijoicm9jZXNtYW5pYyIsImEiOiJjam5hb2NzazQyZWxoM2tydWd0OHN5OThkIn0.2kz8ZH1-HH-TkOh1anDHDA'
                }).addTo(maps[index]);

                L.marker([lat, lng], {icon: icons}).addTo(maps[index]);
            }
        });
    }
}
